export const AREAS = {
  FETCH: 'FETCH_AREAS',
  SUCCESS: 'FETCH_SUCCESS_AREAS',
  FAIL: 'FETCH_FAIL_AREAS',
};

export const fetchAreas = countryId => ({
  type: AREAS.FETCH,
  countryId,
});

export const fetchAreasSuccess = payload => ({
  type: AREAS.SUCCESS,
  payload,
});

export const fetchAreasFail = () => ({
  type: AREAS.FAIL,
});
