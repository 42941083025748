export const BID = {
  FETCH: 'FETCH_BID',
  SUCCESS: 'FETCH_SUCCESS_BID',
  FAIL: 'FETCH_FAIL_BID',
};

export const BID_ENTITIES_DOCTORS = {
  FETCH: 'FETCH_BID_ENTITIES_DOCTORS',
  SUCCESS: 'FETCH_SUCCESS_BID_ENTITIES_DOCTORS',
  FAIL: 'FETCH_FAIL_BID_ENTITIES_DOCTORS',
};

export const CHANGE_BID_ENTITIES_DOCTORS = {
  SAVE: 'SAVE_BID_ENTITIES_DOCTORS',
  SUCCESS: 'SAVE_SUCCESS_BID_ENTITIES_DOCTORS',
  FAIL: 'SAVE_FAIL_BID_ENTITIES_DOCTORS',
};

export const CHANGE_BID_STATUS = {
  SAVE: 'SAVE_BID_STATUS',
  SUCCESS: 'SAVE_BID_STATUS_SUCCESS',
  FAIL: 'SAVE_BID_STATUS_FAIL',
};

export const CHANGE_PAYMENT_DATE = {
  SAVE: 'SAVE_PAYMENT_DATE',
  SUCCESS: 'SAVE_PAYMENT_DATE_SUCCESS',
  FAIL: 'SAVE_PAYMENT_DATE_FAIL',
};

export const EXPORT_CAMPAIGN = {
  SAVE: 'EXPORT_CAMPAIGN',
  SUCCESS: 'EXPORT_CAMPAIGN_SUCCESS',
  FAIL: 'EXPORT_CAMPAIGN_FAIL',
};

export const REFILLADSCACHE_WEB_AGGREGATOR = {
  SUBMIT: 'REFILLADSCACHE_WEB_AGGREGATOR',
  SUCCESS: 'REFILLADSCACHE_WEB_AGGREGATOR_SUCCESS',
  FAIL: 'REFILLADSCACHE_WEB_AGGREGATOR_FAIL',
};

export const REFILLADSCACHE_MOBILE_AGGREGATOR = {
  SUBMIT: 'REFILLADSCACHE_MOBILE_AGGREGATOR',
  SUCCESS: 'REFILLADSCACHE_MOBILE_AGGREGATOR_SUCCESS',
  FAIL: 'REFILLADSCACHE_MOBILE_AGGREGATOR_FAIL',
};

export const updateAdsCacheWebAggregator = () => ({
  type: REFILLADSCACHE_WEB_AGGREGATOR.SUBMIT,
});

export const updateAdsCacheWebAggregatorSuccess = () => ({
  type: REFILLADSCACHE_WEB_AGGREGATOR.SUCCESS,
});

export const updateAdsCacheWebAggregatorFail = () => ({
  type: REFILLADSCACHE_WEB_AGGREGATOR.FAIL,
});

export const updateAdsCacheMobileAggregator = () => ({
  type: REFILLADSCACHE_MOBILE_AGGREGATOR.SUBMIT,
});

export const updateAdsCacheMobileAggregatorSuccess = () => ({
  type: REFILLADSCACHE_MOBILE_AGGREGATOR.SUCCESS,
});

export const updateAdsCacheMobileAggregatorFail = () => ({
  type: REFILLADSCACHE_MOBILE_AGGREGATOR.FAIL,
});

export const fetchBid = payload => ({
  type: BID.FETCH,
  payload,
});

export const fetchBidSuccess = payload => ({
  type: BID.SUCCESS,
  payload,
});

export const fetchBidFail = () => ({
  type: BID.FAIL,
});

export const fetchBidEntitiesDoctors = payload => ({
  type: BID_ENTITIES_DOCTORS.FETCH,
  payload,
});

export const fetchBidEntitiesDoctorsSuccess = payload => ({
  type: BID_ENTITIES_DOCTORS.SUCCESS,
  payload,
});

export const fetchBidEntitiesDoctorsFail = () => ({
  type: BID_ENTITIES_DOCTORS.FAIL,
});

export const saveBidEntitiesDoctors = payload => ({
  type: CHANGE_BID_ENTITIES_DOCTORS.SAVE,
  payload,
});

export const saveBidEntitiesDoctorsSuccess = () => ({
  type: CHANGE_BID_ENTITIES_DOCTORS.SUCCESS,
});

export const saveBidEntitiesDoctorsFail = () => ({
  type: CHANGE_BID_ENTITIES_DOCTORS.FAIL,
});

export const saveBidStatus = payload => ({
  type: CHANGE_BID_STATUS.SAVE,
  payload,
});

export const saveBidStatusSuccess = () => ({
  type: CHANGE_BID_STATUS.SUCCESS,
});

export const saveBidStatusFail = () => ({
  type: CHANGE_BID_STATUS.FAIL,
});

export const savePaymentDate = payload => ({
  type: CHANGE_PAYMENT_DATE.SAVE,
  payload,
});

export const savePaymentDateSuccess = () => ({
  type: CHANGE_PAYMENT_DATE.SUCCESS,
});

export const savePaymentDateFail = () => ({
  type: CHANGE_PAYMENT_DATE.FAIL,
});

export const exportCampaign = payload => ({
  type: EXPORT_CAMPAIGN.SAVE,
  payload,
});

export const exportCampaignSuccess = () => ({
  type: EXPORT_CAMPAIGN.SUCCESS,
});

export const exportCampaignFail = () => ({
  type: EXPORT_CAMPAIGN.FAIL,
});
