import { combineReducers } from 'redux';

import specialties from './Specialties';
import city from './City';
import area from './Area';
import bid from './Bid';

export default combineReducers({
  specialties,
  city,
  area,
  bid,
});
