import { Component } from 'react';
import Cookies from 'js-cookie';
import Proptypes from 'prop-types';

class Authentication extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const authToken = Cookies.get('AUTH_TOKEN');

    if (!authToken && window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
    if (authToken && window.location.pathname === '/login') {
      window.location.href = '/dashboard';
    }
  };

  render() {
    const { children } = this.props;
    return children;
  }
}

Authentication.propTypes = {
  children: Proptypes.node,
};

export default Authentication;
