const URLS = {
  // login: ,
  changePaymentDate: `${process.env.REACT_APP_ADS_API}/api/bids/paymentenddate`,
  changeBidsStatus: `${process.env.REACT_APP_ADS_API}/api/bids/status`,
  getBids: `${process.env.REACT_APP_ADS_API}/api/bids`,
  getBidsEntitiesDoctors: `${process.env.REACT_APP_VDOC_CORE}/api/Entities/LoadDoctors`,
  saveBidsEntitiesDoctors: `${process.env.REACT_APP_ADS_API}/api/bids/doctor`,
  exportExcel: `${process.env.REACT_APP_ADS_API}/api/bids/exportexcel`,
  getCities: `${process.env.REACT_APP_STATIC_API}/api/City/GetCitiesByCountryId`,
  getAreas: `${process.env.REACT_APP_STATIC_API}/api/Area/GetAreasByCountryId`,
  getSpecialties: `${process.env.REACT_APP_VDOC}/api/Specialities/GetMainSpecialities`,
  updateAdsCacheWebAggregator: `${process.env.REACT_APP_WEB_AGGREGATOR_URL}`,
  updateAdsCacheMobileAggregator: `${process.env.REACT_APP_MOBILE_AGGREGATOR_URL}`,
  // downloadCampaingTemplate: ,
  // downloadChildCampaignTemplate: ,
  // uploadCampaing: ,
  // exportCampaingXLS: ,
};

export default URLS;
