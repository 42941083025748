import { fork } from 'redux-saga/effects';

import areaSaga from './Area';
import citySaga from './City';
import specialtiesSaga from './Specialties';
import bidSaga from './Bid';

const sagas = [areaSaga, citySaga, specialtiesSaga, bidSaga];

export default function* rootSaga() {
  yield sagas.map(saga => fork(saga));
}
