import {
  BID,
  BID_ENTITIES_DOCTORS,
  CHANGE_BID_ENTITIES_DOCTORS,
  CHANGE_BID_STATUS,
  CHANGE_PAYMENT_DATE,
  EXPORT_CAMPAIGN,
  REFILLADSCACHE_MOBILE_AGGREGATOR,
  REFILLADSCACHE_WEB_AGGREGATOR,
} from '../actions/Bid';
import { status } from '../../enum';

const initialState = {
  bids: [],
  bidsLoadState: status.SHOULD_CALL_API,
  changeBidEntitiesDoctorsState: status.SHOULD_CALL_API,
  changeBidsLoadState: status.SHOULD_CALL_API,
  changePaymentDateLoadState: status.SHOULD_CALL_API,
  exportCampaignState: status.SHOULD_CALL_API,
  adsCacheWebAggregatorState: status.SHOULD_CALL_API,
  adsCacheMobileAggregatorState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case REFILLADSCACHE_MOBILE_AGGREGATOR.SUBMIT:
      return { ...state, adsCacheMobileAggregatorState: status.SUBMITING };
    case REFILLADSCACHE_MOBILE_AGGREGATOR.SUCCESS: {
      return { ...state, adsCacheMobileAggregatorState: status.SUCCESS };
    }
    case REFILLADSCACHE_MOBILE_AGGREGATOR.FAIL:
      return { ...state, adsCacheMobileAggregatorState: status.FAIL };

    case REFILLADSCACHE_WEB_AGGREGATOR.SUBMIT:
      return { ...state, adsCacheWebAggregatorState: status.SUBMITING };
    case REFILLADSCACHE_WEB_AGGREGATOR.SUCCESS: {
      return { ...state, adsCacheWebAggregatorState: status.SUCCESS };
    }
    case REFILLADSCACHE_WEB_AGGREGATOR.FAIL:
      return { ...state, adsCacheWebAggregatorState: status.FAIL };

    case BID.FETCH:
      return { ...state, bidsLoadState: status.FETCHING, bids: [] };
    case BID.SUCCESS: {
      return { ...state, bidsLoadState: status.SUCCESS, bids: payload.payload };
    }
    case BID.FAIL:
      return { ...state, bidsLoadState: status.FAIL };

    case BID_ENTITIES_DOCTORS.FETCH:
      return { ...state, bidsEntitiesDoctorsLoadState: status.FETCHING, bidsEntitiesDoctors: [] };
    case BID_ENTITIES_DOCTORS.SUCCESS: {
      return {
        ...state,
        bidsEntitiesDoctorsLoadState: status.SUCCESS,
        bidsEntitiesDoctors: payload.payload,
      };
    }
    case BID_ENTITIES_DOCTORS.FAIL:
      return { ...state, bidsEntitiesDoctorsLoadState: status.FAIL };

    case CHANGE_BID_ENTITIES_DOCTORS.SAVE:
      return { ...state, changeBidEntitiesDoctorsState: status.FETCHING };
    case CHANGE_BID_ENTITIES_DOCTORS.SUCCESS:
      return { ...state, changeBidEntitiesDoctorsState: status.SUCCESS };
    case CHANGE_BID_ENTITIES_DOCTORS.FAIL:
      return { ...state, changeBidEntitiesDoctorsState: status.FAIL };

    case CHANGE_BID_STATUS.SAVE:
      return { ...state, changeBidsLoadState: status.FETCHING };
    case CHANGE_BID_STATUS.SUCCESS:
      return { ...state, changeBidsLoadState: status.SUCCESS };
    case CHANGE_BID_STATUS.FAIL:
      return { ...state, changeBidsLoadState: status.FAIL };

    case CHANGE_PAYMENT_DATE.SAVE:
      return { ...state, changePaymentDateLoadState: status.FETCHING };
    case CHANGE_PAYMENT_DATE.SUCCESS:
      return { ...state, changePaymentDateLoadState: status.SUCCESS };
    case CHANGE_PAYMENT_DATE.FAIL:
      return { ...state, changePaymentDateLoadState: status.FAIL };

    case EXPORT_CAMPAIGN.SAVE:
      return { ...state, exportCampaignState: status.FETCHING };
    case EXPORT_CAMPAIGN.SUCCESS:
      return { ...state, exportCampaignState: status.SUCCESS };
    case EXPORT_CAMPAIGN.FAIL:
      return { ...state, exportCampaignState: status.FAIL };

    default:
      return state;
  }
};
