import { Cookie } from '@vezeeta/web-utils';

export const status = {
  SHOULD_CALL_API: 'SHOULD_CALL_API',
  FETCHING: 'FETCHING',
  SUBMITING: 'SUBMITING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

export const CountriesIds = {
  1: 'Egypt',
  2: 'Jordan',
  3: 'Lebanon',
  4: 'Saudi Arabia',
  5: 'Nigeria',
  6: 'Kenya',
  241: 'Global',
};

export const Countries = [
  {
    fieldValue: 'Egypt',
    value: 1,
    searchable: undefined,
    fieldImage: null,
    key: 1,
    children: 'Egypt',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Jordan',
    value: 2,
    searchable: undefined,
    fieldImage: null,
    key: 2,
    children: 'Jordan',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Lebanon',
    value: 3,
    searchable: undefined,
    fieldImage: null,
    key: 3,
    children: 'Lebanon',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Saudi Arabia',
    value: 4,
    searchable: undefined,
    fieldImage: null,
    key: 4,
    children: 'Saudi Arabia',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Nigeria',
    value: 5,
    searchable: undefined,
    fieldImage: null,
    key: 5,
    children: 'Nigeria',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Kenya',
    value: 6,
    searchable: undefined,
    fieldImage: null,
    key: 6,
    children: 'Kenya',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Global',
    value: 241,
    searchable: undefined,
    fieldImage: null,
    key: 241,
    children: 'Global',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
];

export const SortBy = [
  {
    fieldValue: 'Area Bid, Ascending',
    value: 1,
    searchable: undefined,
    fieldImage: null,
    key: 1,
    children: 'Area Bid, Ascending',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Area Bid, Descending',
    value: 2,
    searchable: undefined,
    fieldImage: null,
    key: 2,
    children: 'Area Bid, Descending',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'City Bid, Ascending',
    value: 3,
    searchable: undefined,
    fieldImage: null,
    key: 3,
    children: 'City Bid, Ascending',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'City Bid, Descending',
    value: 4,
    searchable: undefined,
    fieldImage: null,
    key: 4,
    children: 'City Bid, Descending',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Created On, Ascending',
    value: 5,
    searchable: undefined,
    fieldImage: null,
    key: 5,
    children: 'Created On, Ascending',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Created On, Descending',
    value: 6,
    searchable: undefined,
    fieldImage: null,
    key: 6,
    children: 'Created On, Descending',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
];

export const SortByIds = {
  1: {
    sortByValue: 'AreaAmount',
    sortDirection: 'ASC',
  },
  2: {
    sortByValue: 'AreaAmount',
    sortDirection: 'DES',
  },
  3: {
    sortByValue: 'CityAmount',
    sortDirection: 'ASC',
  },
  4: {
    sortByValue: 'CityAmount',
    sortDirection: 'DES',
  },
  5: {
    sortByValue: 'CreateDate',
    sortDirection: 'ASC',
  },
  6: {
    sortByValue: 'CreateDate',
    sortDirection: 'DES',
  },
};

export const countriesISOCodes = {
  1: 'EG',
  2: 'JO',
  3: 'LB',
  4: 'SA',
  5: 'NG',
  6: 'KE',
  241: 'GO',
};

export const CityAreaStatus = [
  {
    fieldValue: 'All Status',
    value: undefined,
    searchable: undefined,
    fieldImage: null,
    key: 9,
    children: 'All Status',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'No bid submitted',
    value: 1,
    searchable: undefined,
    fieldImage: null,
    key: 1,
    children: 'No bid submitted',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Bidding',
    value: 2,
    searchable: undefined,
    fieldImage: null,
    key: 2,
    children: 'Bidding',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Pending',
    value: 3,
    searchable: undefined,
    fieldImage: null,
    key: 3,
    children: 'Pending',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Won',
    value: 4,
    searchable: undefined,
    fieldImage: null,
    key: 4,
    children: 'Won',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Lost',
    value: 5,
    searchable: undefined,
    fieldImage: null,
    key: 5,
    children: 'Lost',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Paid',
    value: 6,
    searchable: undefined,
    fieldImage: null,
    key: 6,
    children: 'Paid',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'Unpaid',
    value: 7,
    searchable: undefined,
    fieldImage: null,
    key: 7,
    children: 'Unpaid',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
  {
    fieldValue: 'outbidded',
    value: 8,
    searchable: undefined,
    fieldImage: null,
    key: 8,
    children: 'outbidded',
    disabled: false,
    groupBy: undefined,
    groupIcon: undefined,
  },
];

export const CityAreaStatusList = [
  {
    fieldValue: 'No bid submitted',
    value: 1,
  },
  {
    fieldValue: 'Bidding',
    value: 2,
  },
  {
    fieldValue: 'Pending',
    value: 3,
  },
  {
    fieldValue: 'Won',
    value: 4,
  },
  {
    fieldValue: 'Lost',
    value: 5,
  },
  {
    fieldValue: 'Paid',
    value: 6,
  },
  {
    fieldValue: 'Unpaid',
    value: 7,
  },
  {
    fieldValue: 'outbidded',
    value: 8,
  },
];

export const header = [
  {
    key: 'Authorization',
    value: `Bearer ${Cookie.get('AUTH_TOKEN')}`,
  },
];

export const TableHeader = [
  'CreatedOn',
  'Provider Name',
  'Specialty',
  'City',
  'Area',
  'Doctors',
  'City bid',
  'Area bid',
  'City status',
  'Area status',
];

export const BulkStatus = [
  {
    name: 'No bid submitted',
    statusId: 1,
  },
  {
    name: 'Pending',
    statusId: 3,
  },
  {
    name: 'Won',
    statusId: 4,
  },
  {
    name: 'Lost',
    statusId: 5,
  },
  {
    name: 'Paid',
    statusId: 6,
  },
  {
    name: 'Unpaid',
    statusId: 7,
  },
  {
    name: 'outbidded',
    statusId: 8,
  },
];

export const PROVIDERTYPE = {
  SINGLEDOCTOR: 'SingleDoctor',
  ENTITY: 'Entity',
};
