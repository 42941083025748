import { CITIES } from '../actions/City';
import { status } from '../../enum';

const initialState = {
  cities: [],
  loadedCountryCities: [],
  loadState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case CITIES.FETCH:
      return { ...state, loadState: status.FETCHING, cities: [] };
    case CITIES.SUCCESS: {
      return { ...state, loadState: status.SUCCESS, cities: payload.payload.cities };
    }
    case CITIES.FAIL:
      return { ...state, loadState: status.FAIL };

    default:
      return state;
  }
};
