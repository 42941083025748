export const CITIES = {
  FETCH: 'FETCH_CITIES',
  SUCCESS: 'FETCH_SUCCESS_CITIES',
  FAIL: 'FETCH_FAIL_CITIES',
};

export const fetchCities = countryId => ({
  type: CITIES.FETCH,
  countryId,
});

export const fetchCitiesSuccess = payload => ({
  type: CITIES.SUCCESS,
  payload,
});

export const fetchCitiesFail = () => ({
  type: CITIES.FAIL,
});
