import { takeLatest, put } from 'redux-saga/effects';
import { STATUS, API } from '@vezeeta/web-utils';
import URLS from '../../urls';
import { AREAS, fetchAreasSuccess, fetchAreasFail } from '../actions/Area';

function* fetchAreas(action) {
  const areaAPI = new API();
  const response = yield areaAPI.get(`${URLS.getAreas}?countryId=${action.countryId}&languageId=1`);

  if (STATUS.isSuccess(response.status)) {
    yield put(fetchAreasSuccess({ areas: response.data, countryId: action.countryId }));
  } else {
    yield put(fetchAreasFail());
  }
}

function* areaSaga() {
  yield takeLatest(AREAS.FETCH, fetchAreas);
}

export default areaSaga;
