import { SPECIALTIES } from '../actions/Specialties';
import { status } from '../../enum';

const initialState = {
  specialties: [],
  loadState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case SPECIALTIES.FETCH:
      return { ...state, loadState: status.FETCHING, specialties: [] };
    case SPECIALTIES.SUCCESS: {
      return { ...state, loadState: status.SUCCESS, specialties: payload.payload.specialties };
    }
    case SPECIALTIES.FAIL:
      return { ...state, loadState: status.FAIL };
    default:
      return state;
  }
};
