import { takeLatest, put } from 'redux-saga/effects';
import { STATUS, API } from '@vezeeta/web-utils';
import URLS from '../../urls';
import { CITIES, fetchCitiesSuccess, fetchCitiesFail } from '../actions/City';

function* fetchCities(action) {
  const cityAPI = new API();
  const response = yield cityAPI.get(
    `${URLS.getCities}?countryId=${action.countryId}&languageId=1`,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(fetchCitiesSuccess({ cities: response.data, countryId: action.countryId }));
  } else {
    yield put(fetchCitiesFail());
  }
}

function* citySaga() {
  yield takeLatest(CITIES.FETCH, fetchCities);
}

export default citySaga;
