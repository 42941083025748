export const SPECIALTIES = {
  FETCH: 'FETCH_SPECIALTIES',
  SUCCESS: 'FETCH_SUCCESS_SPECIALTIES',
  FAIL: 'FETCH_FAIL_SPECIALTIES',
};

export const fetchSpecialties = countryId => ({
  type: SPECIALTIES.FETCH,
  countryId,
});

export const fetchSpecialtiesSuccess = payload => ({
  type: SPECIALTIES.SUCCESS,
  payload,
});

export const fetchSpecialtiesFail = () => ({
  type: SPECIALTIES.FAIL,
});
