import { takeLatest, put } from 'redux-saga/effects';
import { STATUS, API } from '@vezeeta/web-utils';
import Cookies from 'js-cookie';
import URLS from '../../urls';
import { PROVIDERTYPE } from '../../enum';

import {
  BID,
  CHANGE_BID_ENTITIES_DOCTORS,
  CHANGE_BID_STATUS,
  CHANGE_PAYMENT_DATE,
  EXPORT_CAMPAIGN,
  fetchBidSuccess,
  fetchBidFail,
  fetchBidEntitiesDoctorsSuccess,
  fetchBidEntitiesDoctorsFail,
  saveBidEntitiesDoctorsSuccess,
  saveBidEntitiesDoctorsFail,
  saveBidStatusSuccess,
  saveBidStatusFail,
  savePaymentDateSuccess,
  savePaymentDateFail,
  exportCampaignSuccess,
  exportCampaignFail,
  REFILLADSCACHE_MOBILE_AGGREGATOR,
  REFILLADSCACHE_WEB_AGGREGATOR,
  updateAdsCacheMobileAggregatorFail,
  updateAdsCacheMobileAggregatorSuccess,
  updateAdsCacheWebAggregatorSuccess,
  updateAdsCacheWebAggregatorFail,
} from '../actions/Bid';

function* updateAdsCacheMobileAggregatorAPI() {
  const api = new API();
  const authToken = Cookies.get('AUTH_TOKEN');
  const header = [
    {
      key: 'Authorization',
      value: `bearer ${authToken}`,
    },
  ];
  const response = yield api.get(`${URLS.updateAdsCacheMobileAggregator}`, header);
  if (STATUS.isSuccess(response.status)) {
    yield put(updateAdsCacheMobileAggregatorSuccess());
  } else {
    yield put(updateAdsCacheMobileAggregatorFail());
  }
}

function* updateAdsCacheWebAggregatorAPI() {
  const api = new API();
  const authToken = Cookies.get('AUTH_TOKEN');
  const header = [
    {
      key: 'Authorization',
      value: `bearer ${authToken}`,
    },
  ];
  const response = yield api.get(`${URLS.updateAdsCacheWebAggregator}`, header);
  if (STATUS.isSuccess(response.status)) {
    yield put(updateAdsCacheWebAggregatorSuccess());
  } else {
    yield put(updateAdsCacheWebAggregatorFail());
  }
}

function* savePaymentDate(action) {
  const changePaymentDateAPI = new API();
  const authToken = Cookies.get('AUTH_TOKEN');
  const header = [
    {
      key: 'Authorization',
      value: `bearer ${authToken}`,
    },
  ];
  const response = yield changePaymentDateAPI.post(
    `${URLS.changePaymentDate}`,
    action.payload,
    header,
  );
  if (STATUS.isSuccess(response.status)) {
    yield put(savePaymentDateSuccess());
  } else {
    yield put(savePaymentDateFail());
  }
}

function* fetchBids(action) {
  const {
    AdEndDate,
    AdStartDate,
    AreaKey,
    AreaStatus,
    BiddingEndDate,
    BiddingStartDate,
    CampaignEndDate,
    CampaignStartDate,
    CityKey,
    CityStatus,
    CurrentPage,
    LastStatusUpdateDate,
    PageSize,
    SpecialtyCountry,
    SortBy,
    SortDirection,
    countryId,
  } = action.payload;
  const bidsAPI = new API();
  const authToken = Cookies.get('AUTH_TOKEN');
  const header = [
    {
      key: 'Authorization',
      value: `bearer ${authToken}`,
    },
  ];
  const response = yield bidsAPI.get(
    `${URLS.getBids}?${LastStatusUpdateDate &&
      `LastStatusUpdateDate=${LastStatusUpdateDate}`}${SpecialtyCountry &&
      `&SpecialtyCountry=${SpecialtyCountry}`}${AdEndDate &&
      `&AdEndDate=${AdEndDate}`}${AdStartDate && `&AdStartDate=${AdStartDate}`}${AreaKey &&
      `&AreaKey=${AreaKey}`}${AreaStatus && `&AreaStatus=${AreaStatus}`}${BiddingEndDate &&
      `&BiddingEndDate=${BiddingEndDate}`}${BiddingStartDate &&
      `&BiddingStartDate=${BiddingStartDate}`}${CampaignEndDate &&
      `&CampaignEndDate=${CampaignEndDate}`}${CampaignStartDate &&
      `&CampaignStartDate=${CampaignStartDate}`}${CityKey && `&CityKey=${CityKey}`}${CityStatus &&
      `&CityStatus=${CityStatus}`}${CurrentPage && `&CurrentPage=${CurrentPage}`}${PageSize &&
      `&PageSize=${PageSize}`}${SortBy && `&SortBy=${SortBy}`}${SortDirection &&
      `&SortDirection=${SortDirection}`}${countryId && `&CountryId=${countryId}`}`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    const bidsData = response.data.Bids;
    const entityBidsOnlyData = bidsData.filter(bid => bid.ProviderType === PROVIDERTYPE.ENTITY);
    const entityBidsOnlyObj = entityBidsOnlyData.map(entityBid => ({
      BranchKey: entityBid.BranchKey,
      SpecialityKey: entityBid.CampaignAd.CountrySpecialityKey,
    }));

    const doctorsDataAPI = new API();
    const doctorsResponse = yield doctorsDataAPI.post(
      URLS.getBidsEntitiesDoctors,
      {
        BranchesQuery: entityBidsOnlyObj,
      },
      header,
    );

    yield put(fetchBidSuccess(response.data));

    if (STATUS.isSuccess(doctorsResponse.status)) {
      yield put(fetchBidEntitiesDoctorsSuccess(doctorsResponse.response.ReturnObject));
    } else {
      yield put(fetchBidEntitiesDoctorsFail());
    }
  } else {
    yield put(fetchBidFail());
  }
}

function* saveBidEntitiesDoctors(action) {
  const saveBidEntitiesDoctorsAPI = new API();
  const authToken = Cookies.get('AUTH_TOKEN');
  const header = [
    {
      key: 'Authorization',
      value: `bearer ${authToken}`,
    },
  ];
  const response = yield saveBidEntitiesDoctorsAPI.post(
    `${URLS.saveBidsEntitiesDoctors}`,
    action.payload,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(saveBidEntitiesDoctorsSuccess());
  } else {
    yield put(saveBidEntitiesDoctorsFail());
  }
}

function* exportCampaigns(action) {
  const {
    AdEndDate,
    AdStartDate,
    AreaKey,
    AreaStatus,
    BiddingEndDate,
    BiddingStartDate,
    CampaignEndDate,
    CampaignStartDate,
    CityKey,
    CityStatus,
    LastStatusUpdateDate,
    SpecialtyCountry,
    SortBy,
    SortDirection,
    EmailAddress,
    countryId,
  } = action.payload;

  const exportCampaignAPI = new API();
  const authToken = Cookies.get('AUTH_TOKEN');
  const header = [
    {
      key: 'Authorization',
      value: `bearer ${authToken}`,
    },
  ];
  const response = yield exportCampaignAPI.get(
    `${URLS.exportExcel}?${LastStatusUpdateDate &&
      `LastStatusUpdateDate=${LastStatusUpdateDate}`}${SpecialtyCountry &&
      `&SpecialtyCountry=${SpecialtyCountry}`}${AdEndDate &&
      `&AdEndDate=${AdEndDate}`}${AdStartDate && `&AdStartDate=${AdStartDate}`}${AreaKey &&
      `&AreaKey=${AreaKey}`}${AreaStatus && `&AreaStatus=${AreaStatus}`}${BiddingEndDate &&
      `&BiddingEndDate=${BiddingEndDate}`}${BiddingStartDate &&
      `&BiddingStartDate=${BiddingStartDate}`}${CampaignEndDate &&
      `&CampaignEndDate=${CampaignEndDate}`}${CampaignStartDate &&
      `&CampaignStartDate=${CampaignStartDate}`}${CityKey && `&CityKey=${CityKey}`}${CityStatus &&
      `&CityStatus=${CityStatus}`}${SortBy && `&SortBy=${SortBy}`}${SortDirection &&
      `&SortDirection=${SortDirection}`}${EmailAddress &&
      `&EmailAddress=${EmailAddress}`}${countryId && `&CountryId=${countryId}`}`,
    header,
  );
  if (STATUS.isSuccess(response.status)) {
    yield put(exportCampaignSuccess());
  } else {
    yield put(exportCampaignFail());
  }
}

function* saveBidsStatus(action) {
  const changeBidsStatusAPI = new API();
  const authToken = Cookies.get('AUTH_TOKEN');
  const header = [
    {
      key: 'Authorization',
      value: `bearer ${authToken}`,
    },
  ];
  const response = yield changeBidsStatusAPI.post(
    `${URLS.changeBidsStatus}`,
    action.payload,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(saveBidStatusSuccess(response.data));
  } else {
    yield put(saveBidStatusFail());
  }
}

function* bidSaga() {
  yield takeLatest(BID.FETCH, fetchBids);
  yield takeLatest(CHANGE_BID_ENTITIES_DOCTORS.SAVE, saveBidEntitiesDoctors);
  yield takeLatest(CHANGE_BID_STATUS.SAVE, saveBidsStatus);
  yield takeLatest(CHANGE_PAYMENT_DATE.SAVE, savePaymentDate);
  yield takeLatest(EXPORT_CAMPAIGN.SAVE, exportCampaigns);
  yield takeLatest(REFILLADSCACHE_MOBILE_AGGREGATOR.SUBMIT, updateAdsCacheMobileAggregatorAPI);
  yield takeLatest(REFILLADSCACHE_WEB_AGGREGATOR.SUBMIT, updateAdsCacheWebAggregatorAPI);
}

export default bidSaga;
