import { takeLatest, put } from 'redux-saga/effects';
import { STATUS, API } from '@vezeeta/web-utils';
import URLS from '../../urls';
import { countriesISOCodes } from '../../enum';
import { SPECIALTIES, fetchSpecialtiesSuccess, fetchSpecialtiesFail } from '../actions/Specialties';

function* fetchSpecialties(action) {
  const specialtiesAPI = new API();
  const header = [
    {
      key: 'Language',
      value: `en-${countriesISOCodes[action.countryId]}`,
    },
  ];

  const response = yield specialtiesAPI.get(`${URLS.getSpecialties}`, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(fetchSpecialtiesSuccess({ specialties: response.data, countryId: action.countryId }));
  } else {
    yield put(fetchSpecialtiesFail());
  }
}

function* specialtiesSaga() {
  yield takeLatest(SPECIALTIES.FETCH, fetchSpecialties);
}

export default specialtiesSaga;
