import { AREAS } from '../actions/Area';
import { status } from '../../enum';

const initialState = {
  areas: [],
  loadedCountryAreas: [],
  loadState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case AREAS.FETCH:
      return { ...state, loadState: status.FETCHING, areas: [] };
    case AREAS.SUCCESS: {
      return { ...state, loadState: status.SUCCESS, areas: payload.payload.areas };
    }
    case AREAS.FAIL:
      return { ...state, loadState: status.FAIL };

    default:
      return state;
  }
};
